import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from "./componant/Footer/Footer";
import Header from "./componant/Header/Header";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Assets/css/style.css'
import './Assets/css/responsive.css'
import './Assets/css/font.css'
import Home from "./componant/Home/Home";
import Login from "./componant/Login/Login";
import Registration from "./componant/Registration/Registration";
import ForgetPassword from "./componant/Login/ForgetPassword";
import ImageGenerator from "./componant/user/ImageGenerator";
import Imagegallery from "./componant/user/ImageGallery";
import Dashboard from "./componant/user/Dashboard";
// import Subscriptions from "./componant/user/Subscriptions";
import { useSelector } from "react-redux";
import MainRequest from "./mainRequest";
import MyAccount from "./componant/user/MyAccount";
import Invoice from "./componant/user/my-account/Invoice";
import AboutUs from "./componant/static_pages/AboutUs";
import Pricing from "./componant/static_pages/Pricing";
import Contactus from "./componant/static_pages/ContactUs";
import Privacypolicy from "./componant/static_pages/PrivacyPolicy";
import TermsCondition from "./componant/static_pages/TermsAndConditions";
import 'react-loading-skeleton/dist/skeleton.css'
import { Faq } from "./componant/static_pages/Faq";
import ResetPassword from "./componant/Login/ResetPassword";
import Thankyou from "./componant/static_pages/Thankyou";
import Verification from "./componant/Login/varification";
import HelpCenter from "./componant/user/HelpCenter";
import Refund from "./componant/static_pages/Refund";
import ImageEditor from "./componant/user/ImageEditor";
import Maintenance from "./componant/Maintenance/index";
import { useState } from "react";
import CookieConsent from "react-cookie-consent";

function App() {
  const [homeShow, sethomeShow] = useState(true)
  const isLogin = useSelector(state => state.persistedReducer.home.userData.is_login);
  function enableTracking() {
    const trackingId = "G-KEB25KQ076"; // Replace with your GA4 measurement ID
    window.gtag("config", trackingId, {
      anonymize_ip: true, // Optional: anonymize IP for privacy compliance
    });
  }

  
  return (
    <div className="App">
      <BrowserRouter>
        {homeShow ? <Header /> : ''}
        <Routes>
          <Route index element={homeShow ? <Home /> : <Maintenance />} />
          <Route path="/login" element={<Login />} />
          {/* <Route path="/verification" element={<Verification />} /> */}
          <Route path="/registration" element={<Registration />} />
          <Route path="/password/reset" element={<ForgetPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/user/image-generator/:prompt" element={<ImageGenerator />} />
          <Route path="/user/image-generator" element={<ImageGenerator />} />
          <Route path="/user/image-gallery" element={<Imagegallery />} />
          <Route path="/user/my-account" element={<MyAccount />} />
          <Route path="/user/help-center" element={<HelpCenter />} />
          <Route path="/user/dashboard" element={<Dashboard />} />
          <Route path="/user/invoice" element={<Invoice />} />
          <Route path="/user/thank-you" element={<Thankyou />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/contact-us" element={<Contactus />} />
          <Route path="/privacy-policy" element={<Privacypolicy />} />
          <Route path="/refund-policy" element={<Refund />} />
          <Route path="/terms-condition" element={<TermsCondition />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/editor" element={<ImageEditor />} />
       
          {/* <Route path="/maintenance" element={<Maintenance />} /> */}

        </Routes>

        <MainRequest />
        {homeShow ? <Footer /> : ''}
      <CookieConsent
  location="bottom"
  buttonText="Accept"
  cookieName="myAwesomeCookieName2"
  style={{ background: "#222" }}
  buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
  expires={150}
  onAccept={() => {
      console.log("User accepted tracking");
          enableTracking();
  }}
  enableDeclineButton
  onDecline={() => {
    console.log("User declined tracking");
  }}
>
We use cookies to improve your experience on our site. By using our site, you agree to our use of cookies.{" "}
 
</CookieConsent>
      </BrowserRouter>

    </div>
  );
}

export default App;
